import request from '../utils/request'

export function loginByStudent(student_email, student_pwd) {
    return request.post('./index.php', {
        function: 'loginByStudent',
        student_email,
        student_pwd
    })
}

export function loginByContact(contact_email, contact_pwd) {
    return request.post('./index.php', {
        function: 'loginByContact',
        contact_email,
        contact_pwd
    })
}


export function getStudentClasses(student_id) {
    return request.post('./index.php', {
        function: 'getStudentClasses',
        student_id
    })
}

export function getClassCatalogue(student_id, class_id) {
    return request.post('./index.php', {
        function: 'getClassCatalogue',
        student_id,
        class_id
    })
}

export function checkAndInitClassRecord(student_id, class_id) {
    return request.post('./index.php', {
        function: 'checkAndInitClassRecord',
        student_id,
        class_id
    })
}

export function submitAnswer(params) {
    return request.post('./index.php', {
        function: 'submitAnswer',
        student_id: params.student_id,
        class_id: params.class_id,
        session_id: params.session_id,
        content_id: params.content_id,
        quiz_id: params.quiz_id,
        quiz_index: params.quiz_index,
        answer: params.answer,
        correct: params.correct,
        content_index: params.content_index,
        session_index: params.session_index,
    })
}

export function requestClassRecordData(params) {
    return request.post('./index.php', {
        function: 'requestClassRecordData',
        type: params.type,
        student_id: params.student_id,
        record_id: params.record_id,
        session_id: params.session_id,
        content_id: params.content_id,
        quiz_index: params.quiz_index,
        content_index: params.content_index,
        session_index: params.session_index
    })
}

export function getStudentErrorList(student_id, class_id) {
    return request.post('./index.php', {
        function: 'getStudentErrorList',
        student_id,
        class_id
    })
}

export function reportQuestion(student_id, content) {
    return request.post('./index.php', {
        function: 'reportQuestion',
        student_id,
        content
    })
}

export function getReportList(student_id) {
    return request.post('./index.php', {
        function: 'getReportList',
        student_id
    })
}

export function collectionQuiz(params) {
    return request.post('./index.php', {
        function: 'collectionQuiz',
        student_id: params.student_id,
        class_id: params.class_id,
        session_id: params.session_id,
        content_id: params.content_id,
        quiz_id: params.quiz_id,
        note: params.note
    })
}

export function removeCollectionQuiz(collection_id) {
    return request.post('./index.php', {
        function: 'removeCollectionQuiz',
        collection_id
    })
}

export function getCollectionList(student_id, class_id) {
    return request.post('./index.php', {
        function: 'getCollectionList',
        student_id,
        class_id
    })
}

export function searchCollection(student_id, class_id, key_word) {
    return request.post('./index.php', {
        function: 'searchCollection',
        student_id,
        class_id,
        key_word
    })
}

export function getDataList(student_id) {
    return request.post('./index.php', {
        function: 'getDataList',
        student_id
    })
}

export function getOGStudentSubjects(contact_id) {
    return request.post('./index.php', {
        function: 'getOGStudentSubjects',
        contact_id
    })
}
export function getOGStudentErrorStatistics(contact_id) {
    return request.post('./index.php', {
        function: 'getOGStudentErrorStatistics',
        contact_id
    })
}

export function getContactClasses(contact_id) {
    return request.post('./index.php', {
        function: 'getContactClasses',
        contact_id
    })
}
export function getContactDataList(contact_id) {
    return request.post('./index.php', {
        function: 'getContactDataList',
        contact_id
    })
}

export function aiQuestion(contents) {
    return request.post('./index.php', {
        function: 'aiQuestion',
        contents,
    })
}